import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Having Productive Breaks`}</h1>
    <p>{`Working full-time, being a self-taught developer, working on side projects and do some odd client work can be exhausting.`}</p>
    <p>{`If you are like me, you find yourself juggling your time between full-time job, family, friends and all the other side gigs. Finding time to have a real break can be hard since you will feel that you haven't been doing anything for a long time and you will just feel like it will be impossible to silence that voice in your head saying that you should be working.`}</p>
    <p>{`There are many ways to get more productive. You probable know at least 5 different methods and strategies to be productive in order to squeeze as much time from those 24 hours.`}</p>
    <p>{`I've joined makerlog x days a go, I worked on side projects for 151 days straight. But these last 7 days haven't been as productive as the others. I've been busier at work, working longer hours and having less time to rest which affected my ability to get much done at home.`}</p>
    <p>{`On day 151 I've decided that I had enough of trying to force myself to do things since I usually would spend a long time looking at the screen and not working. Sometimes you just need to push yourself away from the screen, switch off that nagging voice and chill out to fully recover.`}</p>
    <p>{`Obviously, in these 151 days I had some days that wheren't very productive, but others there it seemed that I was on fire. One thing I have learned is that you can do a lot of beneficial things that are related to work and still leaving you enough time to have a break and recover your energy.`}</p>
    <p>{`A few things that I do when I feel that I need a break are:`}</p>
    <ul>
      <li parentName="ul">{`Read technical blog posts - this helps me improve my skills and get new knowledge and ways to implement things.`}</li>
      <li parentName="ul">{`Listen to podcasts - I really enjoy podcasts related to tech, not only ones which talk about new stuff happening in tech but also personal journeys into tech.`}</li>
      <li parentName="ul">{`Marketing a project - Let's be honest, we probably need to give a bit more attention to marketing if we want our projects to gain traction.`}</li>
      <li parentName="ul">{`Contact a potential client - If you are thinking to do some freelancing gigs, contacting a potential client is a great way to use your time and it shouldn't take too much of it.`}</li>
      <li parentName="ul">{`Read a book - books are great because they can give you ideas for a project, they can help you with something that you are stuck with or you can learn new stuff.`}</li>
      <li parentName="ul">{`Connect with others - go to meetups, chat with other devs online and create connections.`}</li>
      <li parentName="ul">{`Do design stuff - doing stuff related with design can be great to start up your criativity. You can also improve a project by doing minor tweaks to the design before jumping into code.`}</li>
    </ul>
    <p>{`All these activities shouldn't take you too much and you can enjoy that spare time to have a break and recover from chaotic days. But remember if you are feeling that you need to force yourself to work and nothing good comes out, perhaps you just need to have a good break in order to fully recover.`}</p>
    <p>{`Remember is better to lose one or two days than to do a whole week of unproductive tasks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      